












































































































import ProductCard from "./components/m-product-card.vue";
import { Component, Vue } from "vue-property-decorator";
import EventBus from "@/assets/js/EventBus";
import { isInCart } from "@/assets/js/Utils";
import { Mutation, State } from "vuex-class";
interface payloadType {
  share_id: string | (string | null)[],
  type: string,
  search_value: string,
  page: number,
  min_width?: number,
  max_width?: number
}
@Component({
  components: {
    ProductCard,
  },
})
export default class CVHome extends Vue {
  private value1 = "all";
  private value2 = '';
  // private value3 = 0;
  private option1 = [
    { text: "全部分类", value: "all" },
    { text: "衣柜—趟门衣柜", value: "衣柜—趟门衣柜" },
    { text: "衣柜—掩门衣柜2", value: "衣柜—掩门衣柜2" },
    { text: "衣柜—床侧衣柜组合", value: "衣柜—床侧衣柜组合" },
    { text: "餐厅装饰柜—矮柜", value: "餐厅装饰柜—矮柜" },
    { text: "多功能书柜书桌组合", value: "多功能书柜书桌组合" },
    { text: "多功能书柜组合", value: "多功能书柜组合" },
  ];
  private option2= [
    { text: '不限', value: '' },
    { text: '0-500', value: "0-500" },
    { text: '501-1000', value: '501-1000' },
    { text: '1001-1500', value: '1001-1500' },
    { text: '1501-2000', value: "1501-2000" },
    { text: '2001-2500', value: "2001-2500" },
    { text: '2501-3000', value: "2501-3000" },
    { text: '3001-3500', value: "3001-3500" },
    { text: '3501-4000', value: "3501-4000" },
    { text: '4001-5000', value: "4001-5000" },
    { text: '5001-6000', value: "5001-6000" },
  ];
  // private   option3= [
  //   { text: '不限', value: 0 },
  //   { text: '1700', value: 1700 },
  //   { text: 1900, value: 1900 } ];
  private keywords = ""; //当前搜索的关键词
  private isSearch = false; //当前用户是否在搜索状态
  private isLoading = false; // 加载状态
  private finished = false; // 是否加载完毕
  private total = 0; //总的数据条数
  private page = 1; //当前页
  private list: any[] = []; //存放商品数据
  private userPhone = ""; //用户的电话号码
  @State sharePhone: any;
  @Mutation setSharePhone: any;

  selectChange(value: number, index: number): void {
    //下拉框的值改变
    this.page = 1;
    this.getInitList(); //筛选数据
  }

  startSearch(): void {
    //用户点击了搜索
    this.isSearch = true;
  }

  onSearch(val: string): void {
    //用户按下enter键搜索或者点击搜索
    if (!this.list.length || !val) return;
    this.keywords = val;
    // this.list = []
    this.page = 1;
    this.getInitList(); //重新获取列表
  }

  onCancel(): void {
    //用户取消搜索
    this.clearSearch();
  }

  loadMore(): void {
    //加载更多
    if (!this.list.length) {
      this.isLoading = false;
      return; //如果没有数据就不加载更多
    }
    this.page += 1;
    this.$get("/big_home/get_product_models", {
      share_id: this.$route.query.shareId ?? "", //分享id
      type: this.value1, //筛选类型
      search_value: this.keywords, //搜索值
      page: this.page,
    }).then((res: any) => {
      const newList = res.data.data.res.map((el: any) => {
        return { ...el, isAdded: isInCart(el.id) };
      });
      this.list = [...this.list, ...newList];
      // 加载状态结束
      this.isLoading = false;
      // 数据全部加载完成
      if (this.list.length >= this.total) {
        this.finished = true;
      }
    });
  }
  getInitList() {
    this.page = 1;
    
    let payload:payloadType = {
      share_id: this.$route.query.shareId ?? "", //分享id
      type: this.value1, //筛选类型
      search_value: this.keywords, //搜索值
      page: 1,
    }
    if(this.value2){
      payload.min_width = Number(this.value2.split('-')[0]);
      payload.max_width = Number(this.value2.split('-')[1]);
    }
    this.$get("/big_home/get_product_models", payload).then((res: any) => {
      const data = res.data.data;
      this.total = data.total; //总数量
      if (!this.sharePhone) {
        this.setSharePhone(data.share_phone);
      }
      if (!data.res || !data.res.length) {
        //搜索不到结果
        this.list = [];
        return;
      }
      this.list = data.res.map((el: any) => {
        return { ...el, isAdded: isInCart(el.id) };
      });
    });
  }
  clearSearch(): void {
    //搜索后没有数据，清空搜索数据
    console.log("清空搜索");
    this.isSearch = false;
    this.keywords = "";
    this.userPhone = "";
    this.getInitList();
  }
  onSubmit(): void {
    //用户提交电话号码
    this.$post("/big_home/feedback_search", {
      phone: this.userPhone,
      remark: this.keywords,
    }).then((res: any) => {
      if (res.data.status === 1) {
        this.$toast("提交成功");
      }
    });
    this.clearSearch();
  }

  changeAdded(id: number) {
    const i = this.list.findIndex((el: any) => el.id === id);
    const newList = [...this.list];
    newList[i].isAdded = true;
    this.list = [...newList];
  }

  mounted(): void {
    this.getInitList();
    EventBus.$on("changeManyAdded", (ids: number[]) => {
      const newList = this.list.map((el: any) => {
        if (ids.includes(el.id)) return { ...el, isAdded: false };
        return { ...el };
      });
      this.list = [...newList];
    });
  }
}
