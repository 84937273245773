




























































import { Component, Prop, Vue } from "vue-property-decorator";
import { areaList } from '@vant/area-data';
@Component
export default class gUserInfo extends Vue {
   private  showUserInfo=false ;// 是否展示地址列表
   private  userInfo = { // 用户信息
     name:'',
     phone:'',
     provinceCity:'',
     address:'',
     remark:'',
   }
   private  showArea = false;
   private areaList = areaList;
  @Prop() private isShowUserInfoList!: boolean;  //是否展示用户信息页面
  @Prop() private isEdtior!: boolean;           //是否为修改
   private addressList!: any;           //表单数据
  @Prop() private close!: () => void;        //关闭

   closeprovinceCityList(): void{ //关闭个人信息地址列表
     this.close()
    }

   onConfirm(values:any) {
      this.userInfo.provinceCity = values
        .filter((item:any) => !!item)
        .map((item:any) => item.name)
        .join('/');
      this.showArea = false;
    }
   onSubmit(values:any) {
      this.$emit('onSubmitUserInfo',values)
    }
    mounted() {
      if (this.addressList) {
        this.userInfo = this.addressList;
      }
    }
}
