

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class gQrCode extends Vue {
  @Prop() private title!: string;
}
