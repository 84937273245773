















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import gUserInfo from "../../../components/g-userInfo.vue";
import { reduceCart } from "../../../assets/js/Utils";
import { State } from "vuex-class";
import gHeader from "@/components/g-header.vue";
import { Dialog } from "vant";
@Component({
  components: {
    gUserInfo, //用户信息地址列表组件
    gHeader,
    [Dialog.Component.name]: Dialog.Component,
  },
  filters: {
    getFirst(str: string): string {
      return str ? JSON.parse(str)[0].replace("http:", "https:") : "";
    },
  },
})
export default class CVCart extends Vue {
  @State openId: any;
  private isEdtior = true; //是否为编辑模式
  private goodsList: Array<any> = []; //购物车列表
  private isShowUserInfoList = false; // 是否展示用户信息列表
  private isShowSubmitButton = true; //是否展示提交订单按钮
  private checkGoods: Array<any> = []; //选中的商品id列表
  private name = ""; //姓名
  private phone = ""; //电话
  private province = ""; //省
  private city = ""; //市
  private address = ""; //详细地址
  private remark = ""; //备注
  private checkedGoodsList: Array<any> = []; //选中的列表
  private submitGoodsList: Array<any> = []; //提交的列表
  private title = "购物车"; //导航栏中间的名字无
  private checkAllFlag = false; //全选的标准量
  private totalShoppingCart = 0; // 选中商品的总合计

  toDetail(id: any): void {
    //跳转到商品详情页
    this.$router.push({ name: "CV-Render3D", params: { id } });
  }
  checkAll() {
    //全选按钮
    (this.$refs.checkboxGroup as any).toggleAll(this.checkAllFlag);
  }
  handleChange() {
    // 单选按钮决定是否全选
    this.totalShoppingCart = 0;
    this.goodsList
      .filter((item) => {
        return this.checkGoods.includes(item.id);
      })
      .forEach((value) => {
        this.totalShoppingCart += value.total_price;
      });
    this.checkAllFlag =
      this.checkGoods.length === this.goodsList.length && this.goodsList.length
        ? true
        : false;
  }
  toMyOrder() {
    // 跳转到我的订单
    this.$router.push({ name: "CV-Home", params: { index: "order" } });
  }
  submitOrder() {
    // 提交订单按钮
    this.isShowSubmitButton = false; // 操作提交联系客服的tabar隐藏
    this.isShowUserInfoList = true; // 输入地址表单显示
    this.goodsList &&
      this.goodsList.forEach((v: any) => {
        if (this.checkGoods.indexOf(v.id) >= 0) {
          this.checkedGoodsList.push(v);
        }
      });
    this.checkedGoodsList.forEach((v: any) => {
      let obj = {
        count: v.count,
        id: v.id,
        color_id: v.color_id,
        mat_code: v.mat_code,
      };
      this.submitGoodsList.push(obj);
    });
  }
  deleteOrder() {
    reduceCart(this.checkGoods);
    this.renderShopCart();
    this.$showToast("删除商品成功！");
    this.checkGoods.length -= this.checkGoods.length; //更新选中商品长度
    this.checkAllFlag =
      this.checkGoods.length === this.goodsList.length && this.goodsList.length
        ? true
        : false;
    this.totalShoppingCart = 0;
    this.goodsList
      .filter((item) => {
        return this.checkGoods.includes(item.id);
      })
      .forEach((value) => {
        this.totalShoppingCart += value.total_price;
      });
  }
  deleteOrderWithConfirm() {
    //删除订单按钮
    Dialog.confirm({
      title: "删除购物车",
      message: "是否确认从购物车删除该商品？",
    })
      .then(() => {
        this.deleteOrder();
      })
      .catch(() => {
        // on cancel
      });
  }
  onSubmitUserInfo(val: any) {
    //获取用户输入信息
    this.isShowSubmitButton = true; // 操作提交联系客服的tabar隐藏
    this.isShowUserInfoList = false; // 输入地址表单显示
    let provinceCity = val.provinceCity.split("/");
    this.province = provinceCity[0]; //省
    this.city = provinceCity[1]; //市
    this.name = val.name; //姓名
    this.phone = val.phone; //电话
    this.address = val.address; //详细地址
    this.remark = val.remark; //备注
    this.$post("/big_home/create_order", {
      //提交订单
      open_id: this.openId,
      address: this.address,
      name: this.name,
      phone: this.phone,
      province: this.province,
      city: this.city,
      remark: this.remark,
      model_list: this.submitGoodsList,
    }).then((res: any) => {
      if (res.data.status == 1) {
        this.deleteOrder(); // 提交订单成功后在购物车删除已提交的订单
        this.$showToast("提交订单成功！");
      }
    });
  }
  close() {
    //关闭用户信息地址列表
    this.isShowSubmitButton = true; // 操作提交联系客服的tabar显示
    this.isShowUserInfoList = false; // 输入地址表单隐藏
  }
  renderShopCart() {
    //渲染购物车列表
    let a: any =
      localStorage.getItem("shopCartArr") &&
      localStorage.getItem("shopCartArr");
    this.goodsList = JSON.parse(a) || [];
    console.log(this.goodsList, "模型列表");
  }
  activated() {
    //keeplive的生命周期
    this.renderShopCart(); //渲染购物车列表
  }
}
